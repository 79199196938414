import { InputLabel, MenuItem, Radio, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  singleTourHightlights,
  singleTourImportant,
  singleTourIncludes,
  singleTourLanguages,
  singleTourNotIncludes,
  singleTourPeople,
} from "../../../data/tour/singleTour";
import { useTours } from "../../../hooks/useTours";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import Loader from "../../../utils/loader";
import TourCTASection from "../../../components/tour/TourCTASection";
import { URLdecode, URLencode } from "../../../utils/url";
import { GoogleAPI } from "../../../utils/key";
import { formatMoney } from "../../../features/utils/formatMoney";
import ISO6391 from "iso-639-1";
import RatingStars from "../../../components/common/RatingStars";
import TourReview from "../../../components/tour/TourReview";
import TourMoreSuggestion from "../../../components/tour/TourMoreSuggestion";
import useLoadLocalStorage from "../../../hooks/useLoadLocalStorage";

function TourOrderSingleTourPage() {
  const navigate = useNavigate();
  const { getTourActivity, getTourAvailability, getDuration } = useTours();
  const params = URLdecode();
  const [tour, setTour] = useState();
  const [tourAvailability, setTourAvailability] = useState();
  useLoadLocalStorage(`tour ${params?.productCode}`, setTour);
  useLoadLocalStorage(
    `tourAvailability ${params?.productCode}`,
    setTourAvailability
  );

  const [coverImageSelected, setCoverImageSelected] = useState(null);
  const [pkg, setPkg] = useState({
    code: "",
    time: 0,
  });

  const [tourLoading, setTourLoading] = useState(false);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [meetingPoint, setMeetingPoint] = useState(false);
  const [pickupPoint, setPickupPoint] = useState(false);
  const [checkAvailability, setCheckAvailability] = useState(false);

  const loadData = async () => {
    const promises = [getTourActivity(setTourLoading)];
    await Promise.all(promises);
    setCheckAvailability(true);
    setPkg({
      code: "",
      time: 0,
    });
  };

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (checkAvailability) {
      getTourAvailability(setAvailabilityLoading);
    }
  }, [
    params?.date,
    params?.adult,
    params?.child,
    params?.infant,
    params?.paxMix,
    params?.check,
  ]);

  useEffect(() => {
    getCoverImageSelected();
  }, [tour]);

  const getCoverImageSelected = () => {
    let cover = "";
    if (tour) {
      if (tour?.images) {
        tour?.images?.map((image, index) => {
          if (image?.isCover === true) {
            cover = image?.variants[12]?.url;
          }
        });
        if (!cover) {
          cover = tour?.images[0]?.variants[12]?.url;
        }
      }
    }
    setCoverImageSelected(cover);
  };

  const handleBook = () => {
    const params = URLdecode();

    let selectedPackage = availabilities(tour, tourAvailability)?.find(
      (pkage) => pkage?.productOptionCode === pkg?.code
    );
    let selectedOption = selectedPackage?.options[pkg?.time];

    let parameters = {
      ...params,
      productOptionCode: pkg?.code,
      startTime: selectedOption?.startTime,
      title: tour?.title,
      image: coverImageSelected,
      cancellationPolicy: JSON.stringify(tour?.cancellationPolicy),
    };

    if (tour?.languageGuides || selectedPackage?.languageGuides) {
      parameters = {
        ...parameters,
        languageGuides: selectedPackage?.languageGuides
          ? JSON.stringify(selectedPackage?.languageGuides)
          : JSON.stringify(tour?.languageGuides),
      };
    }
    navigate(`/tour/userInfo?${URLencode(parameters)}`);
  };

  const getMeetingPointCenter = () => {
    let center = null;
    const start = tour?.logistics?.start;
    if (start) {
      if (start[0]) {
        let loc = start[0]?.location?.ref;
        if (loc) {
          let locations = tour?.locations || [];
          if (locations?.length > 0) {
            let currentLocation = locations?.find(
              (location) => location?.reference === loc
            );
            let tempCenter = currentLocation?.center;
            if (tempCenter?.latitude && tempCenter?.longitude) {
              center = {
                lat: tempCenter.latitude,
                lng: tempCenter.longitude,
              };
            }
          }
        }
      }
    }
    return center;
  };

  const getPickupPointCenter = () => {
    let center = null;
    const start = tour?.logistics?.end;
    if (start) {
      if (start[0]) {
        let loc = start[0]?.location?.ref;
        if (loc) {
          let locations = tour?.locations || [];
          if (locations?.length > 0) {
            let currentLocation = locations?.find(
              (location) => location?.reference === loc
            );
            let tempCenter = currentLocation?.center;
            if (tempCenter?.latitude && tempCenter?.longitude) {
              center = {
                lat: tempCenter.latitude,
                lng: tempCenter.longitude,
              };
            }
          }
        }
      }
    }
    return center;
  };

  const availabilities = (loadedTour, loadedTourAvailability) => {
    let items = [];
    if (loadedTour) {
      let productOptions = loadedTour?.productOptions;
      if (loadedTour?.productOptions) {
        let packages = loadedTourAvailability?.bookableItems;
        packages = packages?.filter((pkg) => pkg.available);
        if (packages) {
          productOptions = productOptions?.map((option) => {
            let packagesInOption = packages?.filter(
              (pkg) => pkg?.productOptionCode === option?.productOptionCode
            );
            return {
              ...option,
              options: packagesInOption,
            };
          });
        }
      } else if (
        !productOptions &&
        loadedTourAvailability?.bookableItems &&
        loadedTourAvailability?.bookableItems.length > 0
      ) {
        let packages = loadedTourAvailability?.bookableItems;
        packages = packages?.filter((pkg) => pkg.available);
        if (packages && packages.length > 0) {
          productOptions = [
            {
              options: packages,
              productOptionCode: undefined,
              title: tour?.title,
            },
          ];
        }
      }
      items = productOptions;
    }
    items = items?.filter((item) => item.options?.length > 0);
    return items;
  };

  const packagePriceDetail = (item) => {
    let detail = {
      price: "",
      detail: "",
      available: "",
      reason: "",
    };

    // if (pkg?.code) {
    let selectedPackage = item;
    if (selectedPackage) {
      let selectedTime = selectedPackage?.options?.find(
        (_, index) => index === pkg?.time
      );
      if (selectedTime) {
        detail.available = selectedTime?.available;
        let reason = selectedTime?.unavailableReason;
        if (reason) {
          reason = reason.replace(/_/g, " ").toLowerCase();
          reason = reason.charAt(0).toUpperCase() + reason.slice(1);
          detail.reason = reason;
        }
        let price = selectedTime?.totalPrice;
        if (price) {
          let farePrice = price?.farePrice;
          detail.price = formatMoney(farePrice?.total);
        }
        let lineItems = selectedTime?.lineItems;
        if (lineItems) {
          let formattedItems = lineItems.map((item) => {
            let ageBand = item.ageBand.toLowerCase();
            ageBand = ageBand.charAt(0).toUpperCase() + ageBand.slice(1);
            if (ageBand === "Child" && item?.numberOfTravelers !== 1)
              ageBand += "ren";
            if (ageBand !== "Child" && item?.numberOfTravelers !== 1)
              ageBand += "s";
            if (item?.numberOfTravelers > 0) {
              return `${item.numberOfTravelers}${ageBand} x ${formatMoney(
                item?.subtotalPrice?.price?.partnerNetPrice
              )}`;
            }
          });

          let resultString = formattedItems.join(", ");
          if (resultString) {
            detail.detail = resultString;
          }
        }
      }
    }
    // }

    return detail;
  };

  const whatToExpect = (description) => {
    let data = [];
    let descriptions = description ? description.split(/\n/) : [];

    descriptions = descriptions.filter((desc) => desc);

    data = descriptions;

    return data;
  };

  const languages = () => {
    let languageString = "";

    let availableLanguages = tour?.languageGuides || [];

    for (let i = 0; i < availableLanguages.length; i++) {
      languageString +=
        i !== 0
          ? `, ${
              ISO6391.getName(availableLanguages[i]?.language) ||
              "Unkown language"
            }`
          : ISO6391.getName(availableLanguages[i]?.language) ||
            "Unkown language";
    }

    return languageString;
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        {/* Page title */}
        {/* <div className={Classname.titleContainer}>
          {`Tour > Orders > New Order >`}{" "}
          <span className={Classname.titleMain}>Single Tour</span>
        </div> */}
        <div className="mt-5"></div>
        <Loader loading={tourLoading} />
        {!tourLoading && tour && (
          <>
            <span className={Classname.detailTitle}>{tour?.title}</span>
            <div className={Classname.detailLocationContainer}>
              <img
                src="/IconLocationGreen.svg"
                alt=""
                className={Classname.detailLocationIcon}
              />
              <span>Heathrow road | Tour provider: GT Travels</span>
            </div>
            {tour?.reviews?.combinedAverageRating && (
              <div className="mb-10 flex items-center gap-2">
                <RatingStars rating={tour?.reviews?.combinedAverageRating} />
                <span>
                  {" "}
                  {tour?.reviews?.totalReviews} Review
                  {tour?.reviews?.totalReviews === 1 ? "" : "s"}{" "}
                </span>
              </div>
            )}
            {/* Images container */}
            <div className={Classname.imagesContainer}>
              <div className={Classname.mainImageContainer}>
                {coverImageSelected && (
                  <img
                    src={coverImageSelected}
                    className={Classname.mainImage}
                    alt=""
                  />
                )}
              </div>
              <div className={Classname.imageRow}>
                {tour &&
                  tour?.images?.map((image, index) => (
                    <img
                      key={index}
                      src={image?.variants[12]?.url}
                      className={Classname.imageRowElement}
                      alt=""
                      onClick={() =>
                        setCoverImageSelected(image?.variants[12]?.url)
                      }
                    />
                  ))}
              </div>
            </div>
            <div className="w-full gap-4 flex flex-wrap items-center">
              <div className={Classname.feature}>
                <img
                  src="/IconBlackTimer.svg"
                  alt=""
                  className={Classname.featureIcon}
                />
                <div className={Classname.featureContent}>
                  <span className={Classname.featureDescription}>
                    {getDuration(tour)}
                  </span>
                </div>
              </div>
              <div className={Classname.feature}>
                <span className="font-bold text-sm">Ticket type:</span>
                <div className={Classname.featureContent}>
                  <span className={Classname.featureDescription}>
                    {tour?.ticketInfo?.ticketTypeDescription}
                  </span>
                </div>
              </div>
              {languages() && (
                <div className={Classname.feature}>
                  <img
                    src="/IconBlackGlobe.svg"
                    alt=""
                    className={Classname.featureIcon}
                  />
                  {languages() && (
                    <div className={Classname.featureContent}>
                      <span className={Classname.featureDescription}>
                        {languages()}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Detail container */}
            <div className="flex gap-10">
              <div className={Classname.detailContainer}>
                {/* Detail */}
                <div className={Classname.detailContent}>
                  <Loader loading={availabilityLoading} />

                  {tourAvailability &&
                    !availabilityLoading &&
                    availabilities(tour, tourAvailability) &&
                    availabilities(tour, tourAvailability)?.length > 0 && (
                      <>
                        <span className={Classname.detailSelectTitleContainer}>
                          Make a selection
                        </span>
                        <div className={Classname.detailSelectContainer}>
                          {tourAvailability &&
                            availabilities(tour, tourAvailability)?.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col flex-1"
                                >
                                  <div className={Classname.detailSelectOption}>
                                    <div
                                      className={
                                        Classname.detailSelectOptionContent
                                      }
                                    >
                                      <div className="hidden lg:flex">
                                        <Radio
                                          checked={
                                            pkg?.code ===
                                            item?.productOptionCode
                                          }
                                          onChange={() =>
                                            setPkg({
                                              code: item.productOptionCode,
                                              time: 0,
                                            })
                                          }
                                          name="radio-buttons"
                                          inputProps={{ "aria-label": "A" }}
                                        />
                                      </div>

                                      <div
                                        className={
                                          Classname.detailSelectOptionContentDetail
                                        }
                                      >
                                        <span
                                          className={
                                            Classname.detailSelectOptionContentTitle
                                          }
                                        >
                                          {item?.title}
                                        </span>
                                        <div className="flex-col-reverse flex lg:flex-col">
                                          <p
                                            className={
                                              Classname.detailSelectOptionContentDescription
                                            }
                                          >
                                            {item?.description?.replace(
                                              /<br\/>/g,
                                              " "
                                            )}
                                          </p>
                                          {pkg.code ===
                                            item?.productOptionCode && (
                                            <div
                                              className={
                                                Classname.detailSelectOptionContentButtonsContainer
                                              }
                                            >
                                              {item?.options &&
                                                item?.options?.length > 0 &&
                                                item?.options?.map(
                                                  (itemOption, index) =>
                                                    itemOption?.startTime ? (
                                                      <button
                                                        onClick={() =>
                                                          setPkg({
                                                            code: item?.productOptionCode,
                                                            time: index,
                                                          })
                                                        }
                                                        className={`${
                                                          Classname.detailSelectOptionContentButton
                                                        } ${
                                                          index === pkg.time
                                                            ? Classname.detailSelectOptionContentButtonActive
                                                            : Classname.detailSelectOptionContentButtonInActive
                                                        }`}
                                                      >
                                                        {itemOption?.startTime}
                                                      </button>
                                                    ) : (
                                                      <></>
                                                    )
                                                )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        Classname.detailSelectOptionDevider
                                      }
                                    ></div>
                                    <div
                                      className={
                                        Classname.detailSelectOptionCTA
                                      }
                                    >
                                      <div
                                        className={
                                          Classname.detailSelectOptionCTA1
                                        }
                                      >
                                        <span
                                          className={
                                            Classname.detailSelectOptionCTAPrice
                                          }
                                        >
                                          {packagePriceDetail(item)?.price}
                                        </span>
                                        <span
                                          className={
                                            Classname.detailSelectOptionCTApersonnel
                                          }
                                        >
                                          {packagePriceDetail(item)?.detail}
                                        </span>
                                      </div>
                                      {pkg.code === item?.productOptionCode && (
                                        <>
                                          {packagePriceDetail(item)
                                            ?.available ? (
                                            <button
                                              onClick={() =>
                                                handleBook(
                                                  item?.productOptionCode
                                                )
                                              }
                                              className={
                                                Classname.detailSelectOptionCTAButton
                                              }
                                            >
                                              Book Now
                                            </button>
                                          ) : (
                                            <span className="font-bold text-red-500 text-sm lg:text-base text-center">
                                              Item not bookable due to{" "}
                                              {packagePriceDetail(item)?.reason}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {index !==
                                    availabilities(tour, tourAvailability)
                                      ?.length -
                                      1 && (
                                    <div
                                      className={
                                        Classname.detailSelectOptionDevider1
                                      }
                                    ></div>
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      </>
                    )}
                  <>
                    <span className={Classname.detailTitle}>Overview</span>
                    <p className={Classname.detailOverviewDescription}>
                      {tour?.description}
                    </p>
                    <div className="flex lg:hidden w-full mt-4 mb-6">
                      <TourCTASection />
                    </div>
                    <span className={Classname.detailTitle}>Experience</span>
                    {/* Highlights */}
                    <div className="flex-col flex">
                      {/* <div className={Classname.detailExperienceContainer}>
                        <div
                          className={Classname.detailExperienceTitleContainer}
                        >
                          Highlights
                        </div>
                        <div
                          className={Classname.detailExperienceDetailContainer}
                        >
                          {singleTourHightlights.map((highlight, index) => (
                            <div
                              className={Classname.detailExperiencePoint}
                              key={index}
                            >
                              <div
                                className={Classname.detailExperienceBullet}
                              ></div>
                              <span>{highlight}</span>
                            </div>
                          ))}
                        </div>
                      </div> */}
                      {/* Description */}
                      {(tour?.itinerary?.activityInfo?.description ||
                        tour?.itinerary?.unstructuredDescription) && (
                        <div className={Classname.detailExperienceContainer}>
                          <div
                            className={Classname.detailExperienceTitleContainer}
                          >
                            What to expect
                          </div>
                          <div
                            className={
                              Classname.detailExperienceDetailContainer
                            }
                          >
                            <p>
                              {whatToExpect(
                                tour?.itinerary?.activityInfo?.description ||
                                  tour?.itinerary?.unstructuredDescription
                              )?.map((description, index) => (
                                <div key={index} className="mb-3">
                                  {description}
                                </div>
                              ))}
                            </p>
                            {/* <a
                            href=""
                            onClick={handleReadMore}
                            className={Classname.detailExperienceLink}
                          >
                            Read more
                          </a> */}
                          </div>
                        </div>
                      )}
                      {/* Includes */}

                      <div className={Classname.detailExperienceContainer}>
                        <div
                          className={Classname.detailExperienceTitleContainer}
                        >
                          Includes
                        </div>
                        <div
                          className={Classname.detailExperienceDetailContainer}
                        >
                          {tour?.inclusions?.map((include, index) => (
                            <div
                              className={Classname.detailExperiencePoint}
                              key={index}
                            >
                              <img
                                src="/IconCheckmark.svg"
                                alt=""
                                className={Classname.detailExperienceIcon}
                              />
                              <span>
                                {include?.categoryDescription} -{" "}
                                {include?.typeDescription}
                              </span>
                            </div>
                          ))}
                          {tour?.exclusions?.map((include, index) => (
                            <div
                              className={Classname.detailExperiencePoint}
                              key={index}
                            >
                              <img
                                src="/IconCloseRed.svg"
                                alt=""
                                className={Classname.detailExperienceIcon}
                              />
                              <span>
                                {include?.categoryDescription} -{" "}
                                {include?.typeDescription}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* Meeting point */}
                      {tour?.logistics?.start && (
                        <div className={Classname.detailExperienceContainer}>
                          <div
                            className={Classname.detailExperienceTitleContainer}
                          >
                            Meeting point
                          </div>
                          <div
                            className={
                              Classname.detailExperienceDetailContainer
                            }
                          >
                            <p>{tour.logistics?.start[0]?.description}</p>
                            {getMeetingPointCenter() && (
                              <>
                                {meetingPoint ? (
                                  <div
                                    style={{ width: "100%" }}
                                    className="h-56 lg:h-80"
                                  >
                                    <GoogleMapReact
                                      bootstrapURLKeys={{
                                        key:
                                          process.env
                                            .REACT_APP_GOOGLE_MAPS_API_KEY ||
                                          GoogleAPI,
                                      }}
                                      defaultCenter={getMeetingPointCenter()}
                                      defaultZoom={15}
                                    ></GoogleMapReact>
                                  </div>
                                ) : (
                                  <span
                                    onClick={() => setMeetingPoint(true)}
                                    className={`${Classname.detailExperienceLink} cursor-pointer`}
                                  >
                                    Check google map
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {/* Pickup point */}
                      {tour?.logistics?.end && (
                        <div className={Classname.detailExperienceContainer}>
                          <div
                            className={Classname.detailExperienceTitleContainer}
                          >
                            Pickup point
                          </div>
                          <div
                            className={
                              Classname.detailExperienceDetailContainer
                            }
                          >
                            <p>{tour.logistics?.end[0]?.description}</p>
                            {getPickupPointCenter() && (
                              <>
                                {pickupPoint ? (
                                  <div
                                    style={{ width: "100%" }}
                                    className="h-56 lg:h-80"
                                  >
                                    <GoogleMapReact
                                      bootstrapURLKeys={{
                                        key:
                                          process.env
                                            .REACT_APP_GOOGLE_MAPS_API_KEY ||
                                          GoogleAPI,
                                      }}
                                      defaultCenter={getPickupPointCenter()}
                                      defaultZoom={15}
                                    ></GoogleMapReact>
                                  </div>
                                ) : (
                                  <span
                                    onClick={() => setPickupPoint(true)}
                                    className={`${Classname.detailExperienceLink} cursor-pointer`}
                                  >
                                    Check google map
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* Important points */}
                      {/* <div className={Classname.detailExperienceContainer}>
                        <div
                          className={Classname.detailExperienceTitleContainer}
                        >
                          Important Information
                        </div>
                        <div
                          className={Classname.detailExperienceDetailContainer}
                        >
                          {singleTourImportant.map((point, index) => (
                            <div
                              className={Classname.detailExperiencePoint}
                              key={index}
                            >
                              <div
                                className={Classname.detailExperienceBullet}
                              ></div>
                              <span>{point}</span>
                            </div>
                          ))}
                        </div>
                      </div> */}
                      {/* Additional points */}
                      <div className={Classname.detailExperienceContainer}>
                        <div
                          className={Classname.detailExperienceTitleContainer}
                        >
                          Other Information
                        </div>
                        <div
                          className={Classname.detailExperienceDetailContainer}
                        >
                          {tour?.additionalInfo?.map((point, index) => (
                            <div
                              className={Classname.detailExperiencePoint}
                              key={index}
                            >
                              <div
                                className={Classname.detailExperienceBullet}
                              ></div>
                              <span>{point?.description}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                  {tour?.reviews?.totalReviews ? <TourReview /> : <></>}
                </div>
              </div>
              <div className="hidden lg:flex w-[500px] mt-20">
                <TourCTASection />
              </div>
            </div>
            <TourMoreSuggestion />
          </>
        )}
      </div>
    </div>
  );
}

export default TourOrderSingleTourPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 ",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",

  imagesContainer: "flex flex-col flex-wrap w-full gap-2 lg:gap-4",
  mainImageContainer: "relative flex h-72 lg:h-[500px]",
  mainImage: "rounded-xl w-full h-full min-w-56 object-cover",
  mainImageCategory:
    "font-bold text-xxxs absolute top-2 left-2 bg-white/70 py-1 px-2 rounded-md",
  mainImageCTA:
    "font-bold text-xxxs absolute bottom-4 right-2 bg-white/70 py-1 px-2 rounded-md cursor-pointer",
  imageRow: "flex overflow-scroll scroll-hide w-full gap-2 lg:gap-4 mb-4",
  imageRowElement: "w-32 lg:w-56 h-32 lg:h-56 rounded-xl cursor-pointer",

  detailSelectTitleContainer:
    "flex py-4 pb-6 font-bold border-t border-gray-200",
  detailSelectContainer:
    "flex flex-col lg:w-full border border-gray-200 rounded-md py-4 mb-10",
  detailSelectOption:
    "w-full px-6 py-5 flex lg:flex-row flex-col items-center gap-10",
  detailSelectOptionContent: "flex items-start flex-1 gap-2",
  detailSelectOptionContentRadio: "",
  detailSelectOptionContentDetail: "flex flex-col",
  detailSelectOptionContentTitle: "font-bold text-xl mb-4",
  detailSelectOptionContentDescription: "text-gray-500",
  detailSelectOptionContentButtonsContainer: "flex flex-wrap gap-3 mt-4",
  detailSelectOptionContentButton:
    "lg:w-32 w-20 h-8 mb-2 lg:mb-0 lg:h-12 font-bold text-xs lg:text-sm rounded-md cursor-pointer",
  detailSelectOptionContentButtonActive: "bg-primary1 text-white",
  detailSelectOptionContentButtonInActive: "bg-gray-600 text-gray-300",
  detailSelectOptionDevider: "lg:flex hidden h-full border-r border-gray-200 ",
  detailSelectOptionDevider1: "flex flex-1 w-full border-t border-gray-200",
  detailSelectOptionCTA:
    "w-full flex flex-col justify-center items-end max-w-sm pr-4",
  detailSelectOptionCTA1:
    "w-full lg:flex hidden flex-col justify-center items-end max-w-sm pr-4",
  detailSelectOptionCTA2:
    "w-full flex lg:hidden flex-col justify-center items-start max-w-sm pr-4",

  detailSelectOptionCTAPrice: "font-bold text-xl ",
  detailSelectOptionCTApersonnel: "text-gray-400 mb-2 text-end",
  detailSelectOptionCTAButton:
    "bg-primary1 text-white font-bold flex items-center justify-center h-12 w-full max-w-xs rounded-md",

  detailContainer: "flex w-full gap-6 mt-6",
  detailContent: "flex flex-col flex-1",
  detailTitle: "text-4xl font-bold mb-1 flex flex-shrink-0",
  detailLocationContainer: "flex items-center gap-2 text-gray-400 mb-2",
  detailLocationIcon: "",
  detailDescription: "text-gray-600 mb-6",
  detailAboutTagsContainer: "flex flex-wrap gap-4 mb-6",
  detailAboutTag: "flex items-center h-20 bg-primary1/10 px-6 gap-4 rounded-md",
  detailAboutTagIcon: "",
  detailAboutTagContentContainer: "flex flex-col",
  detailAboutTagName: "font-bold text-lg",
  detailAboutTagDescription: "text-sm text-gray-600",
  detailContentFormInputs: "flex flex-wrap gap-2 w-full mt-10 mb-20",
  detailContentFormInputContainer: "flex flex-col flex-1",
  detailContentFormInputLabel: "font-bold mb-1 text-gray-600",
  datailContentFormDateInput:
    "w-full border border-primary/20 rounded-md p-2 h-14",
  detailContentFormButton:
    "flex bg-primary1 font-bold text-white rounded-lg h-14 items-center justify-center mt-7",
  detailOverviewDescription: "mb-10",

  detailExperienceContainer:
    "flex flex-col lg:flex-row flex-1 items-start lg:items-center border-t py-4 border-gray-300 my-4 gap-4",
  detailExperienceTitleContainer: "lg:w-28 flex items-center font-bold",
  detailExperienceDetailContainer:
    "flex flex-1 flex-col gap-2 text-gray-500 mb-4 mt-2 text-sm lg:text-base",
  detailExperienceDetailContainer1: "flex flex-1 gap-3 text-gray-500",
  detailExperienceBullet:
    "w-2 lg:w-3 h-2 lg:h-3 flex flex-shrink-0 rounded-full bg-gray-300",
  detailExperienceIcon: "",
  detailExperiencePoint: "flex items-center gap-2",
  detailExperienceLink: "text-primary1  underline underline-offset-2",

  detailCTAContainer:
    "flex flex-col flex-1 lg:max-w-sm border-2 border-gray-300 p-4 h-fit rounded-lg",
  detailCTAtagsContainer: "flex flex-wrap gap-4 mb-4",
  detailCTAtag1:
    "text-sm font-semibold text-red-700 bg-red-600/20 px-2 py-1 rounded-md",
  detailCTAtag2:
    "text-sm font-semibold text-yellow-800 bg-yellow-600/20 px-2 py-1 rounded-md",
  detailCTAtag3:
    "text-sm font-semibold text-green-600 bg-green-600/20 px-2 py-1 rounded-md",
  detailCTATitleContainer:
    "flex items-center justify-between text-sm text-gray-600 mb-6",
  detailCTATitleProvider: "text-lg font-bold mt-4 text-black",

  detailCTABenefit:
    "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  detailCTABenefitTitle: "text-lg font-bold mb-1",
  detailCTABenefitDescription: "flex items-center gap-2 text-sm text-gray-600",
  detailCTABenefitIcon: "",
  detailCTAPriceContainer: "text-gray-600 flex flex-col mb-4",
  detailCTASelectTitle: "font-bold text-lg mb-2",
  detailCTAEditSection:
    "h-14 w-full border border-gray-400 rounded-md flex items-center px-4 gap-2 mb-3",
  detailCTAEditIcon: "",
  detailCTAEditEditingIcon: "cursor-pointer p-2",
  detailCTAEditText: "flex flex-1",
  detailCTAPrice: "font-bold text-xl text-black",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mb-4",
  mobileOverviewSeparator: "mb-2",

  feature: "flex flex-col lg:flex-row items-start lg:items-center gap-2",
  featureContent: "flex flex-col",
  featureIcon: "",
  featureDescription: "font-normal text-gray-500",
};
