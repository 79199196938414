import { useState } from 'react';
import { Icon } from '@iconify/react';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../Logo/Logo';
import { getsubDomain } from '../../../utils/getsubDomain';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CartLink from './CartLink';

const Header2 = ({ overrideImage }) => {
  const state = useSelector(state => state.template);
  const { userData: { user, loggedIn } } = useSelector(state => state.user);
  const externalLinks = useSelector(state => state.crmData?.externalLinks || []); // Fallback if externalLinks is undefined
  const [menuVisible, setMenuVisible] = useState(false);
  const agency = getsubDomain();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;
  let activeLink = 'flight';

  if (pathName?.includes('accommodation')) activeLink = 'stays';
  else if (pathName?.includes('tours')) activeLink = 'tours';

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="overflow-x-auto items-center bg-white shadow-md grid grid-cols-2 md:grid-cols-4 font-poppins origin-top">
      <div className="flex justify-start origin-left px-6 py-2">
        <Logo iconOnly={true} overrideImage={overrideImage} />
      </div>
      <div className="md:hidden flex items-center justify-end px-6 py-2 h-14">
        <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
      </div>
      <div className={`md:flex col-span-2 flex-1 bg-[#EBEFF9] md:bg-white font-bold flex-col md:flex-row items-center gap-8 py-4 ${menuVisible ? 'flex' : 'hidden'}`}>
        <Link to='/order/new/flight' className={`px-3 py-2 rounded-md ${activeLink == "flight" ? 'text-white' : 'text-black'}`} style={{ color: activeLink == "flight" ? state.secondaryColor : "" }}>Flight</Link>
        
        {/* Conditionally render external links if they are not empty */}
        {externalLinks.length > 0 && (
          externalLinks.map((link, index) => (
            <a key={index} href={link.urlLink} target="_blank" rel="noopener noreferrer" className="px-3 py-2 rounded-md text-black" style={{ color: link.includesFooter ? state.secondaryColor : '' }}>
              {link.title}
            </a>
          ))
        )}
        
      </div>
      <div className={`px-6 bg-[#EBEFF9] md:bg-white justify-end flex flex-col md:flex-row items-start md:items-center gap-10 ${menuVisible ? 'block' : 'hidden'} md:flex justify-end origin-right`}>
        <div className={`flex gap-10 items-center cursor-pointer py-4 justify-center md:justify-end w-full`}>
          <button className='flex gap-2 items-center intercomButton'>
            <Icon icon="ph:question" className='text-xl' style={{ color: state.secondaryColor }} />
            <span>Help</span>
          </button>

          <CartLink />

          {loggedIn ?
            <Link to='/account' className='flex gap-2 items-center justify-center rounded-md bg-[#EFF3F5] px-2 py-2'>
              <Icon icon="heroicons:user" className='text-xl' style={{ color: state.secondaryColor }} />
            </Link>
            :
            <Link to='/?view=login' className='flex gap-2 items-center justify-center rounded-md bg-[#EFF3F5] px-2 py-2'>
              <span>Sign In</span>
            </Link>
          }

        </div>
      </div>
    </div>
  );
}

export default Header2;
