export function getSupplierName(defName='') {
    let name = defName;
    switch(name) {
        case 'Intra1A':
            name = 'Amadeus-A1';
             break;
        case 'Intra2A':
            name = 'Amadeus-A2';
            break;
        case 'Intra3A':
            name = 'Amadeus-A3';
            break;
        case 'Intra1T':
            name = 'Travel Port';
            break;
        case 'Intra1S':
            name = 'Sabre';
            break;
        case 'Intra1FR':
            name = 'TravX';
            break;
        case 'Intra1K':
            name = 'TravX1';
            break;
        case 'Intra1PK':
            name = 'TravX2';
            break;
        case 'Intra1XA':
            name = 'TravX3';
            break;
        case 'Intra1MR':
            name = 'TravX4';
            break;
        default: ;
    }
    return groupedSupplierName(name)
}

function groupedSupplierName(val) {
    if(val?.includes('MyAmadeus'))
        return 'MyAmadeus'
    else if(val?.includes('Amadeus'))
        return 'Amadeus'
    else if(val?.includes('MyTravelport'))
        return 'MyTravelport'
    else if(val?.includes('Travelport'))
        return 'Travelport'
    else if(val?.includes('MySabre'))
        return 'MySabre'
    else if(val?.includes('Sabre'))
        return 'Sabre'
    else if(val?.includes('Intra1K') || val?.includes('TravX'))
        return 'TravX'
    
    return val;
}

export function isGDSSupplier(name) {
    const named = getSupplierName(name)

    return "Amadeus Amadeus-A1 Amadeus-A2 Amadeus-A3 Travel Port Travelport Sabre"?.includes(named)
} 